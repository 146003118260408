<template>
    <section class="h-screen flex flex-col justify-center bg-slate-900 relative z-0" id="background">
      <div class="flex flex-col items-center w-full max-w-screen-xl px-4 py-8 mx-auto rounded-3xl lg:grid-cols-12 lg:gap-8 lg:grid -mt-12 sm:mt-0">
        <div class="order-2 pl-6 lg:order-none lg:col-span-7 lg:mr-auto lg:pl-9">
          <h1 class="font-roboto-mono max-w-2xl mb-4 sm:text-[4rem] text-[2rem] font-extrabold tracking-tight leading-none text-white outlined">
            louise <span class="text-white hover:text-owl-pink no-outlined">koustrup</span><span class="blinking text-white">_</span>
          </h1>
          <p class="max-w-2xl mt-3 text-owl-pink font-bold md:text-lg lg:text-xl">
            Projektledelse, adfærdsdesign og udvikling
          </p>
          <div class="flex flex-row md:justify-left mt-4 space-x-4">
            <div class="inline-flex items-center justify-center py-3 text-base font-medium text-white bg-primary-700 rounded-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300">
              Connect
              <svg class="w-5 h-5 ml-2 -mr-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
              </svg>
            </div>
            <a href="https://linkedin.com/in/louisekoustrup" class="inline-flex items-center justify-center px-5 py-3 text-base font-medium border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 text-white">
              LinkedIn
            </a>
          </div>
        </div>
        <div class="order-1 lg:order-none flex justify-center items-center lg:col-span-5 mb-5">
          <img class="rounded-full sm:w-80 w-40 sm:h-80 h-40 mx-auto" id="image" src="https://cdn.fyn.bazo.dk/files/8e9d3c1f-5d88-45b6-9410-1d415c472946/2023-04-28-115916-1660202945155.jpeg" alt="mockup">
        </div>
      </div>
  
      <!-- SVG wave background -->
      <div class="absolute bottom-20 sm:bottom-0 right-0 z-20 p-6">
        <a href="#new-section" class="scroll-smooth inline-block">
              <button class="bg-slate-900 text-owl-pink border-2 border-owl-pink hover:border-slate-900 rounded-full p-2 hover:bg-owl-pink hover:text-slate-900 focus:outline-none transition-all hover:shadow-lg">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
              </button>
            </a>  
      </div>
      <div class="svg-container absolute bottom-10 sm:bottom-0">
            <svg class="wave-svg" viewBox="0 0 1200 200" preserveAspectRatio="none">
          <path d="M0,60 C300,150 600,10 900,90 C1100,130 1400,10 1600,90 L1600,200 L0,200 Z" fill="#fc0183"/>
        </svg>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'BackgroundSection',
  }
  </script>
  
  <style scoped>
  .blinking {
    animation: blink 1s infinite;
    display: inline-block;
  }
  
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .svg-container {
    position: absolute;
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
  
  .wave-svg {
    width: 100%;
    height: 100%;
  }
  
  .outlined {
    font-weight: 700;
    color: transparent !important;
    -webkit-text-stroke: 1px white;
  }
  
  .no-outlined {
    color: none;
    -webkit-text-stroke: 0px transparent;
  }
  </style>
  