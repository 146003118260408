<template>
  <div class="relative container mx-auto px-4">
    <!-- Swiper content -->
    <swiper
      :slides-per-view="1.2"
      :space-between="10"
      :grab-cursor="true"
      :pagination="{
        clickable: true
      }"
      :breakpoints="{
        640: { slidesPerView: 1.2 },  /* Mobile: 1 full slide, part of next */
        768: { slidesPerView: 2 },    /* Tablet: 2 full slides */
        1024: { slidesPerView: 3.2 }  /* Desktop: 3 full slides */
      }"
      class="relative z-10 mySwiper"
      style="width: 100%; height: 100%;"
    >

      <!-- Slide 1 -->
      <swiper-slide>
        <div class="group relative w-full max-w-xs md:max-w-md bg-gray-900 rounded-lg overflow-hidden shadow-lg transition-transform duration-300 card">
          <img src="https://www.teknologisk.dk/_/media/89126&w=1460&h=808&r=cover&_filename=89126_TI%20logo%20DK%20sidestillet.jpg" alt="Course Image 1" class="w-full h-48 object-cover" />
          <!-- Certificate Icon in Top-Left -->
          <div class="absolute top-2 right-2 text-yellow-500 flex items-center">
            <font-awesome-icon :icon="['fas', 'certificate']" class="text-yellow-500"></font-awesome-icon>
            <span class="text-sm text-gray-600 ml-1">Certifikat</span>
          </div>
          <div class="p-4">
            <h3 class="text-lg font-semibold text-white">Agil Projektledelse</h3>
            <p class="text-sm text-gray-200">Introduktion til projektledelse med Agile og Scrum</p>
            <p class="text-xs text-gray-400 pt-2">Tid: 3 dage</p>
          </div>
        </div>
      </swiper-slide>

      <!-- Slide 2 -->
      <swiper-slide>
        <div class="group relative w-full max-w-xs md:max-w-md bg-gray-900 rounded-lg overflow-hidden shadow-lg transition-transform duration-300 card">
          <img src="https://pll.harvard.edu/sites/default/files/styles/16_9_medium/public/course/CS50x_pll.png?itok=PJhUTe4g" alt="Course Image 2" class="w-full h-48 object-cover" />
          <!-- Certificate Icon in Top-Left -->
          <div class="absolute top-2 right-2 text-yellow-500 flex items-center">
            <font-awesome-icon :icon="['fas', 'certificate']" class="text-yellow-500"></font-awesome-icon>
            <span class="text-sm text-gray-200 ml-1">Certifikat</span>
          </div>
          <div class="p-4">
            <h3 class="text-lg font-semibold text-white">CS50</h3>
            <p class="text-sm text-gray-200">Computer Science ved Harvard University.</p>
            <p class="text-xs text-gray-400 pt-2">Tid: 30 dage</p>
          </div>
        </div>
      </swiper-slide>

      <!-- Slide 3 -->
      <swiper-slide>
        <div class="group relative w-full max-w-xs md:max-w-md bg-gray-900 rounded-lg overflow-hidden shadow-lg transition-transform duration-300 card">
          <img src="https://behaviouraleconomics.pmc.gov.au/sites/default/files/styles/slider_image/public/2021-10/beta-slider.png?itok=x_3iLtmt" alt="Course Image 3" class="w-full h-48 object-cover" />
          <!-- Certificate Icon in Top-Left -->
          <div class="absolute top-2 right-2 text-yellow-500 flex items-center">
            <font-awesome-icon :icon="['fas', 'certificate']" class="text-yellow-500"></font-awesome-icon>
            <span class="text-sm text-gray-200 ml-1">Certifikat</span>
          </div>
          <div class="p-4">
            <h3 class="text-lg font-semibold text-white">Behavioural insights for public policy</h3>
            <p class="text-sm text-gray-200">Kort kursus fra den Australske regering i, hvordan de bruger adfærdsdesign i politikker og regeringsprojekter</p>
            <p class="text-xs text-gray-400 pt-2">Tid: 2 timer</p>
          </div>
        </div>
      </swiper-slide>

      <!-- Slide 4 -->
      <swiper-slide>
        <div class="group relative w-full max-w-xs md:max-w-md bg-gray-900 rounded-lg overflow-hidden shadow-lg transition-transform duration-300 card">
          <img src="https://radicaldesigncourse.com/assets/ransom-radical-design@2x.webp" alt="Course Image 4" class="w-full h-48 object-cover" />
          <!-- Certificate Icon in Top-Left -->
          <div class="p-4">
            <h3 class="text-lg font-semibold text-white">Radical Design</h3>
            <p class="text-sm text-gray-200">Design filosofier og værktøjer af Jack McDade</p>
            <p class="text-xs text-gray-400 pt-2">Tid: 8 timer</p>
          </div>
        </div>
      </swiper-slide>

    </swiper>

    <!-- Pagination bullets -->
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'CourseCards',
  components: {
    Swiper,
    SwiperSlide,
    FontAwesomeIcon, // Register the Font Awesome component
  },
};
</script>

<style scoped>
/* Additional custom styles if needed */
</style>
