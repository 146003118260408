<template>
  <section id="new-section" class="relative h-screen flex flex-col justify-center bg-owl-pink z-10">
    <!-- Button Container: Positioned in the top-right corner of this section -->
    <div class="absolute top-0 right-0 z-20 p-6">
      <!-- Original Button: Downward Arrow -->
      <!-- <a href="#new-section" class="scroll-smooth inline-block">
        <button class="bg-slate-900 text-owl-pink border-2 border-owl-pink hover:border-slate-900 rounded-full p-2 hover:bg-owl-pink hover:text-slate-900 focus:outline-none transition-all hover:shadow-lg">
          <svg xmlns="http://www.w3.org/2000/svg" class="sm:h-8 sm:w-8 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </button>
      </a> -->

      <!-- New Button: Upward Arrow -->
      <a href="#background" class="scroll-smooth inline-block">
        <button class="bg-slate-900 text-owl-pink border-2 border-owl-pink hover:border-slate-900 rounded-full p-2 hover:bg-owl-pink hover:text-slate-900 focus:outline-none transition-all hover:shadow-lg">
          <svg xmlns="http://www.w3.org/2000/svg" class="sm:h-8 sm:w-8 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
          </svg>
        </button>
      </a>
    </div>

    <h2 class="text-4xl text-slate-900 font-bold text-center mb-8">Kurser og certificeringer</h2>
    <CourseCards />
  </section>
</template>




<script>
import CourseCards from './CourseCards.vue'

export default {
  name: 'OwlPinkSection',
  components: {
    CourseCards,
  },
}
</script>