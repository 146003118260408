export function initializeLightEffect() {
    document.addEventListener('DOMContentLoaded', () => {
      let isOverImage = false;
      const background = document.getElementById('background');
      const image = document.getElementById('image');
      const svgContainer = document.querySelector('.svg-container');
  
      if (!background || !image || !svgContainer) {
        console.error("One or more required elements not found in the DOM.");
        return;
      }
  
      document.addEventListener('mousemove', (event) => {
        let light = document.querySelector('.light');
  
        if (!light) {
          light = document.createElement('div');
          light.className = 'light';
          background.appendChild(light);
        }
  
        const rect = image.getBoundingClientRect();
        const svgRect = svgContainer.getBoundingClientRect();
        const isInsideImage = (
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom
        );
  
        const isInsideSvg = (
          event.clientY >= svgRect.top &&
          event.clientY <= svgRect.bottom
        );
  
        if (isInsideImage) {
          if (!isOverImage) {
            isOverImage = true;
            light.style.transition = 'all 0.1s ease-out';
            light.style.width = `${rect.width}px`;
            light.style.height = `${rect.height}px`;
            light.style.left = `${rect.left}px`;
            light.style.top = `${rect.top}px`;
            light.style.border = '10px solid #fc0183';
            light.style.borderRadius = '50%';
            light.style.backgroundColor = 'transparent';
            light.style.filter = 'none';
          }
        } else {
          if (isOverImage) {
            isOverImage = false;
            light.style.transition = 'all 0.1s ease-out';
            light.style.width = '30px';
            light.style.height = '30px';
            light.style.border = 'none';
            light.style.borderRadius = '50%';
            light.style.backgroundColor = '#fc0183';
            light.style.filter = 'blur(0px)';
          }
          light.style.left = `${event.clientX - 15}px`;
          light.style.top = `${event.clientY - 15}px`;
        }
  
        if (isInsideSvg) {
          light.style.backgroundColor = '#1e293b'; // slate-900 color
        } else {
          if (!isOverImage) {
            light.style.backgroundColor = '#fc0183'; // owl-pink color
          }
        }
      });
  
      document.addEventListener('mouseleave', () => {
        const light = document.querySelector('.light');
        if (light) {
          light.remove();
        }
      });
    });
  }
  