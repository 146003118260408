import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/css/tailwind.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faCertificate);

createApp(App).component('font-awesome-icon', FontAwesomeIcon).mount('#app');
