<template>
  <div>
    <BackgroundSection />
    <OwlPinkSection />
  </div>
</template>

<script>
import BackgroundSection from './components/BackgroundSection.vue'
import OwlPinkSection from './components/OwlPinkSection.vue'
import { onMounted } from 'vue'
import { initializeLightEffect } from './helpers/lightEffect.js'

export default {
  name: 'App',
  components: {
    BackgroundSection,
    OwlPinkSection,
  },
  setup() {
    onMounted(() => {
      initializeLightEffect();  // Initialize the light effect
    });
  }
}
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  font-family: Arial, sans-serif;
}

.light {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #fc0183;
  border-radius: 50%;
  pointer-events: none;
  transition: all 0.1s ease-out;
}
</style>
